import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Revival
			</title>
			<meta name={"description"} content={"Повертаємо життя класичним колесам!"} />
			<meta property={"og:title"} content={"Головна | Revival"} />
			<meta property={"og:description"} content={"Повертаємо життя класичним колесам!"} />
			<meta property={"og:image"} content={"https://crestovel.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crestovel.com/img/4422483.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crestovel.com/img/4422483.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crestovel.com/img/4422483.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crestovel.com/img/4422483.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="--primary">
						Повертаємо життя класичним авто!
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						Revival{"\t\n\t\t\t\t "}
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
					Ми раді бачити вас тут, де класичні автомобілі отримують нове життя. У Classic Auto Revival ми шануємо історію та красу класичних автомобілів і прагнемо відновити їхню колишню славу. Якщо ви є власником старовинної перлини, яка потребує косметичного ремонту, або проектного автомобіля, який чекає на повну реставрацію, ви знайшли правильне місце. Ми тут, щоб переконатися, що ваш класичний автомобіль знову привертає увагу на дорозі!
					</Text>
					<Button
						background="--color-primary"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--base"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Переглянути адресу
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://crestovel.com/img/1.jpg"
						display="block"
						margin="0px -100px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-11"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Чому обирають нас?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						size="42px"
						color="--light"
						background="--color-primary"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
							Експертиза в класиці
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
						Наші механіки спеціалізуються на класичних автомобілях, привносячи в нашу майстерню десятиліття спільного досвіду.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						size="42px"
						color="--light"
						background="--color-primary"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
							Якісна майстерність
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
						Ми використовуємо лише найкращі матеріали та ретельні технології для відновлення кожного автомобіля.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						size="42px"
						color="--light"
						background="--color-primary"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
							Прозорий сервіс
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--darkL2">
						Ми тримаємо вас в курсі подій, надаючи детальну інформацію на кожному етапі процесу реставрації.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Your Place for Pizza Perfection
					</Text>
					<Image
						src="https://crestovel.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Готові відродити свою класику?
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Їдьте в минуле з гордістю на своєму відреставрованому класичному автомобілі!
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});